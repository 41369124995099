import React from "react";
import { Link } from "gatsby";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import CTA from "components/shared/CTA";
import LargeTitle from "components/services/LargeTitle";
import icon from "images/svg_icons/line-chart.svg";
import { StaticImage } from "gatsby-plugin-image";
import ImageBlock from "components/services/ImageBlock";
import UAGrid from "components/services/UAGrid";

export default () => (
  <>
    <Meta url="/services/acquisition/" />
    <Nav />
    <LargeTitle
      img={icon}
      green="User Acquisition"
      black="Services"
      text="Through mobile advertising, CPD bidding, social and influencer marketing, AppInChina can develop a user acquisition strategy for your app or game in China."
    />
    <ImageBlock
      title="Grow your user base in China"
      text={
        <>
          <p>
            We know which user acquisition (UA) methods work best in China, and
            our team has both the relationships with the major app and game
            stores and the expertise required to put a custom plan in place for
            mobile advertising.
          </p>
          <p>
            Once it's set up, your team can{" "}
            <Link to="/services/acquisition/analytics/">view analytics</Link>{" "}
            and manage your ASO, CPD and media campaigns from your client
            dashboard. And we're always just a message or phone call away.
          </p>
        </>
      }
      img={
        <StaticImage
          src="../../images/photos/using-phone.jpg"
          alt="Alipay"
          placeholder="blurred"
          layout="fixed"
          width={800}
          height={650}
        />
      }
    />
    <UAGrid />
    <CTA />
    <Footer />
  </>
);
